<template>
  <div>
    <v-runtime-template
      v-if="config.template !== undefined"
      :template="`<div>${config.template}</div>`"
    />
  </div>
</template>
<script>
import DashboardWidget from './DashboardWidget';
import VRuntimeTemplate from 'v-runtime-template';
import Api from '@/core/Api';

export default {
  extends: DashboardWidget,
  components: {
    VRuntimeTemplate,
  },
  data () {
    return {
      data: undefined,
    };
  },
  mounted () {
    this.fetchData();
  },
  methods: {
    async fetchData () {
      if (this.config.queryUrl) {
        const payload = await Api.get(this.config.queryUrl);
        this.data = payload.data;
      }
    }
  }
}
</script>
