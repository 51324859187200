<template>
  <div>
    <span style="float: right">
      <a class="button" @click="addItem('text')">
        + text
      </a>
      <a class="button" @click="addItem('chart')">
        + chart
      </a>
      <a class="button" @click="saveDashboard">
        Save
      </a>
    </span>
    Dashboard
    <b-loading :is-full-page="true" :active="isLoading" :can-cancel="false" />
    <Dashboard v-if="!isLoading" :dashboard="dashboard" @editItem="editedItem = $event" @removeItem="removeItem" />
    <div :class="`form-drawer ${editedItem && editedItem.type ? 'visible': ''}`">
      <span class="button remove" @click="editedItem = undefined" style="float: right">x</span>
      <vue-form-generator
        v-if="editedItem && editedItem.type"
        ref="form"
        :schema="schemas[editedItem.type]"
        :model="editedItem"
        class="form"
        tag="div"
      />
    </div>
  </div>
</template>
<script>
import Dashboard from '@/components/dashboard/Dashboard';
import Api from '@/core/Api';

import { ToastProgrammatic as Toast } from 'buefy';
import deepClone from '@/core/utils/deepClone';

export default {
  components: {
    Dashboard,
  },
  data () {
    this.loadDashboard();
    return {
      isLoading: true,
      dashboard: {},
      editedItem: undefined,
      index: 0,
      schemas: {
        text: {
          fields: [{
            type: 'text',
            model: 'title',
            label: 'Titre'
          }, {
            type: 'text',
            model: 'template',
            label: 'template'
          }, {
            type: 'text',
            model: 'queryUrl',
            label: 'Query URL',
          }]
        },
        chart: {
          fields: [{
            type: 'text',
            model: 'title',
            label: 'Titre'
          }, {
            type: 'text',
            model: 'data source',
            label: 'data source'
          }, {
            type: 'text',
            model: 'queryUrl',
            label: 'Query URL',
          }, {
            type: 'text',
            model: 'groupByKey',
            label: 'Group by Key',
          }]
        }
      }
    }
  },
  methods: {
    async loadDashboard () {
      try {
        const payload = await Api.get(`/system_dashboards/${this.$route.params.id}`);
        if (payload.data && payload.data.success) {
          this.dashboard = payload.data.documents[0];
          if (this.dashboard.layout === undefined) {
            this.dashboard.layout = [];
          }
        } else {
          this.dashboard = { layout: [] };
        }
        this.isLoading = false;
      } catch (e) {
        console.error(e);
        if (e.response && e.response.status) {
          this.errorStatus = e.response.status;
          this.$store.dispatch('errors/requestError', { message: 'Unauthorized', error: e, status: e.response.status });
        }
        this.isLoading = false;
      }
    },
    addItem (type) {
      this.dashboard.layout.push({
        x: (this.dashboard.layout.length * 2) % (this.colNum || 12),
        y: this.dashboard.layout.length + (this.colNum || 12), // puts it at the bottom
        w: 2,
        h: 2,
        i: this.dashboard.layout.length,
        type
      });
      console.log('addType');
      this.dashboard = deepClone(this.dashboard);
    },
    removeItem: function (val) {
      console.log('remove', val);
      const index = this.dashboard.layout.map(item => item.i).indexOf(val);
      this.dashboard.layout.splice(index, 1);
      this.dashboard = deepClone(this.dashboard);
    },

    async saveDashboard () {
      await this.$store.dispatch('abstractElements/saveObject', {
        collection: 'system_dashboards',
        object: this.dashboard,
      });
      Toast.open({ message: 'Document sauvegardé', type: 'is-success' });
    }
  }
};
</script>
<style>
.vue-grid-item:not(.vue-grid-placeholder) {
  background: white;
  border: 1px solid black;
  position: relative;
  overflow: hidden;
}
.vue-grid-item .resizing {
  opacity: 0.9;
}
.vue-grid-item .static {
  background: white;
}
.vue-grid-item .text {
  font-size: 24px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
  width: 100%;
}
.vue-grid-item .no-drag {
  height: 100%;
  width: 100%;
}
.vue-grid-item .minMax {
  font-size: 12px;
}
.vue-draggable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>") no-repeat;
  background-position: bottom right;
  padding: 0 8px 8px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: pointer;
}
.form-drawer {
  position: fixed;
  right: -300px;
  width: 300px;
  background: whitesmoke;
  transition: right 0.2s;
  height: 100vh;
  top: 0;
}
.form-drawer.visible {
  right: 0;
}
</style>
