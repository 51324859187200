<template>
  <div>
    <div style="background: #aaa; display: flex">
      <span style="flex-grow: 1">
        {{item.title}}
      </span>
      <span>
        <span class="button is-small edit" @click="$emit('editItem', item)">
          <i class="mdi mdi-wrench" />
        </span>
        <span class="button is-small remove" @click="$emit('removeItem', item.i)">x</span>
      </span>
    </div>
    <div style="position: relative;">
      <slot/>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    }
  }
};
</script>
