<script>
export default {
  props: {
    config: {
      type: Object,
      required: true,
    },
  }
};
</script>
