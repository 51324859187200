<template>
  <div>
    <!--<vc-donut :sections="sections" :size="100" unit="%">{{config.title}}</vc-donut>-->
    <v-frappe-chart
      type="bar"
      :labels="['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']"
      :data="[{ values: [18, 40, 30, 35, 8, 52, 17, -4] }]"
      :colors="['red']"
    />
  </div>
</template>
<script>
//https://frappe.io/charts
import Vue from 'vue';
import Api from '@/core/Api';
import { VFrappeChart } from 'vue-frappe-chart'

import DashboardWidget from './DashboardWidget';

export default {
  extends: DashboardWidget,
  components: {
    VFrappeChart,
  },
  data() {
    return {
      sections: [{ value: 25 }, { value: 25 }],
      data: undefined,
      datacollection: {
        labels: [this.getRandomInt(), this.getRandomInt()],
        datasets: [{
          label: 'Data One',
          backgroundColor: '#f87979',
          data: [this.getRandomInt(), this.getRandomInt()]
        }, {
          label: 'Data One',
          backgroundColor: '#f87979',
          data: [this.getRandomInt(), this.getRandomInt()]
        }]
      }
    };
  },
  mounted () {
    this.fetchData();
  },
  methods: {
    async fetchData () {
      const res = {};
      if (this.config.queryUrl) {
        const payload = await Api.get(this.config.queryUrl);
        this.data = payload.data.documents;
        for (let e of this.data) {
          if (res[e[this.config.groupByKey]] === undefined) {
            res[e[this.config.groupByKey]] = { value: 1, label: e[this.config.groupByKey] };
          } else {
            res[e[this.config.groupByKey]].value ++;
          }
        }
        this.sections = Object.values(res);
      }
    },
    getRandomInt () {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5
    }
  }
}
</script>
