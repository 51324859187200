<template>
  <div>
    <grid-layout :layout.sync="dashboard.layout"
      :col-num="12"
      :row-height="30"
      :is-draggable="draggable"
      :is-resizable="resizable"
      :vertical-compact="true"
      :use-css-transforms="true"
    >
      <grid-item v-for="item in dashboard.layout"
        :key="item.i"
        :static="false"
        :x="item.x"
        :y="item.y"
        :w="item.w"
        :h="item.h"
        :i="item.i"
      >
        <DashboardItemWrapper :item="item" @editItem="$emit('editItem', $event)" @removeItem="$emit('removeItem', $event)">
          <component v-if="item.type" :is="itemWidgetComponentName(item)" :config="item" />
        </DashboardItemWrapper>
      </grid-item>
    </grid-layout>

  </div>
</template>
<script>
import { GridLayout, GridItem } from 'vue-grid-layout';
import DashboardItemWrapper from '@/components/dashboard/DashboardItemWrapper';
import DashboardWidgetText from '@/components/dashboard/widgets/Text';
import DashboardWidgetChart from '@/components/dashboard/widgets/Chart';

export default {
  props: {
    dashboard: {
      type: Object,
      required: true
    }
  },
  components: {
    GridLayout,
    GridItem,
    DashboardItemWrapper,
    DashboardWidgetChart,
    DashboardWidgetText,
  },
  data () {
    return {
      draggable: true,
      resizable: true,
    };
  },
  methods: {
    itemWidgetComponentName(i) {
      const name = i.type[0].toUpperCase() + i.type.slice(1);

      return `DashboardWidget${name}`;
    },
  }
};
</script>
